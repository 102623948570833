<template>
  <div class="body-wrapper">
    <Headernav />
    <div class="header-banner">
      <div class="container header-height flex items-end md:items-center">
        <div class="w-full md:w-6/12 lg:w-5/12 text-center pb-10 md:pb-0">
          <div class="w-full text-2xl md:text-xl lg:text-6xl leading-tight text-white font-bold pb-4 lg:pb-6">
            ENHANCE <br class="hidden md:block">YOUR BEAUTY
          </div>
          <a class="m-auto" href="#contact-area" v-smooth-scroll="{ duration: 2500}"><div class="gold-btn btn-width">SET AN APPOINTMENT NOW!</div></a>
        </div>
        <div class="w-full md:w-6/12 lg:w-7/12 hidden md:block"><img src="/images/header-img.png" alt="Header Image" /></div>
      </div>
    </div>

    <div class="w-full pt-12 pb-12">
      <div class="w-full pt-4 pb-4 lg:pt-8 lg:pb-8"></div>
      <div class="container md:flex">
        <div class="w-full md:w-6/12 lg:w-5/12 pr-0 md:pr-4">
          <img src="/images/peofile-img.jpg" alt="Profile Image" />
        </div>
        <div class="w-full md:w-6/12 lg:w-7/12 pt-8">
          <div class="w-full text-2xl md:text-xl lg:text-4xl leading-tight text-gray-800 font-semi-bold">
            VICENTE FRANCISCO Q. FIRMALO
          </div>
          <div class="w-full text-lg lg:text-2xl leading-tight text-gold font-medium pb-8">
            MD, FPAPRAS, DPBPS
          </div>
          <div class="w-full pb-8">
            <table cellpadding="0" cellspacing="0" class="w-full">
              <tr>
                <td valign="top">
                  <svg class="w-5 h-5 text-gold" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M9 5l7 7-7 7"></path></svg>
                </td>
                <td class="text-md text-gray-800 font-light pb-4">
                  Fellow, Philippine Association of Plastic, Reconstructive, and Aesthetic Surgeons (PAPRAS)
                </td>
              </tr>
              <tr>
                <td valign="top">
                  <svg class="w-5 h-5 text-gold" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M9 5l7 7-7 7"></path></svg>
                </td>
                <td class="text-md text-gray-800 font-light pb-4">
                  Fellow, Philippine College of Surgeons
                </td>
              </tr>
              <tr>
                <td valign="top">
                  <svg class="w-5 h-5 text-gold" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M9 5l7 7-7 7"></path></svg>
                </td>
                <td class="text-md text-gray-800 font-light pb-4">
                  DIPLOMATE, Philippine Board of Plastic Surgery
                </td>
              </tr>
              <tr>
                <td valign="top">
                  <svg class="w-5 h-5 text-gold" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M9 5l7 7-7 7"></path></svg>
                </td>
                <td class="text-md text-gray-800 font-light pb-4">
                  International Member, American Society of Plastic Surgeons
                </td>
              </tr>
              <tr>
                <td valign="top">
                  <svg class="w-5 h-5 text-gold" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M9 5l7 7-7 7"></path></svg>
                </td>
                <td class="text-md text-gray-800 font-light pb-4">
                  MEMBER, International Society of Plastic and Regenerative Surgeons (ISPRES)
                </td>
              </tr>
              <tr>
                <td valign="top">
                  <svg class="w-5 h-5 text-gold" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M9 5l7 7-7 7"></path></svg>
                </td>
                <td class="text-md text-gray-800 font-light pb-4">
                  Vounteer Surgeon, Smile Train and Operation Smile
                </td>
              </tr>
            </table>
          </div>
          <div class="w-full">
            <img cass="pr-0 md:pr-4" src="/images/profile-certificate.jpg" alt="Profile Certificate" />
          </div>
        </div>
      </div>
      <div class="w-full pt-4 pb-4 lg:pt-8 lg:pb-8"></div>
    </div>

    <div class="dark-bg pt-12 pb-12">
      <div class="w-full pt-4 pb-4 lg:pt-8 lg:pb-8"></div>
      <div class="container md:flex">
        <div class="w-full md:w-6/12 pb-8 md:pb-0 pr-0 md:pr-10">
          <div class="text-2xl md:text-xl lg:text-4xl leading-tight text-white font-semi-bold pb-8">
            ABOUT US
          </div>
          <div class="text-md text-white font-light">
            Firmalo Plastic Surgery provides high quality standards of beauty enhancement through plastic surgery. Our doctor has been providing excellent service by attending to each patient's special needs and delivering the best possible solutions to their concerns. We prioritize safety and cleanliness to ensure that every patient feels secure every time they visit our clinic.
          </div>
        </div>
        <div class="w-full md:w-6/12 border-l-6 border-gold pt-8 pb-8">
          <div class="w-full text-3xl md:text-2xl lg:text-4xl leading-tight text-white font-semi-bold pl-6 lg:pl-10 pb-8">
            BOOK AN <br class="hidden md:block"><span class="text-gold">APPOINTMENT NOW!</span>
          </div>
          <div class="w-full pl-6 lg:pl-10 pb-8">
             <a class="m-auto" href="tel:09175046056"><div class="text-center text-xl lg:text-2xl bg-gold hover:bg-white btn-width transition py-2">0917 504 6056</div></a>
          </div>
        </div>
      </div>
      <div class="w-full pt-4 pb-4 lg:pt-8 lg:pb-8"></div>
    </div>

    <div class="silver-bg pt-12 pb-12">
      <div class="w-full pt-4 pb-4 lg:pt-8 lg:pb-8"></div>
      <div class="container">
      <div class="w-full text-center text-2xl md:text-xl lg:text-4xl leading-tight text-dark-white font-semi-bold pb-8">
        SERVICES
      </div>
      <div class="w-full md:flex">
        <div class="w-full md:w-4/12 pr-6 pb-10 md:pb-0">
          <div class="w-full flex justify-center pb-3">
            <img src="/images/service01.png" alt="Firmalo Services" />
          </div>
          <div class="w-full text-center text-lg lg:text-xl leading-tight text-dark-white font-medium">
            BREAST AUGMENTATION
          </div>
        </div>
        <div class="w-full md:w-4/12 pl-3 pr-3 pb-10 md:pb-0">
          <div class="w-full flex justify-center pb-3">
            <img src="/images/service02.png" alt="Firmalo Services" />
          </div>
          <div class="w-full text-center text-lg lg:text-xl leading-tight text-dark-white font-medium">
            LIPOSUCTION
          </div>
        </div>
        <div class="w-full md:w-4/12 pl-6">
          <div class="w-full flex justify-center pb-3">
            <img src="/images/service03.png" alt="Firmalo Services" />
          </div>
          <div class="w-full text-center text-lg lg:text-xl leading-tight text-dark-white font-medium">
            GYNECOMASTIA
          </div>
        </div>
      </div>
      </div>
      <div class="w-full pt-4 pb-4 lg:pt-8 lg:pb-8"></div>
    </div>

    <div class="gold-bg pt-12 pb-12">
      <div class="w-full pt-4 pb-4 lg:pt-8 lg:pb-8"></div>
      <div class="container">
        <div class="w-full text-center text-2xl md:text-xl lg:text-4xl leading-tight text-dark-white font-semi-bold pb-8">
          OTHER SERVICES
        </div>
        <div class="w-full lg:flex">
          <div class="w-full lg:w-6/12 md:flex">
            <div class="w-full lg:w-6/12">
              <table cellpadding="0" cellspacing="0" class="w-full">
                <tr>
                  <td valign="top">
                    <svg class="w-5 h-5 text-white" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M9 5l7 7-7 7"></path></svg>
                  </td>
                  <td class="text-md text-white font-light pb-4">
                    EYELID SURGERY
                  </td>
                </tr>
                <tr>
                  <td valign="top">
                    <svg class="w-5 h-5 text-white" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M9 5l7 7-7 7"></path></svg>
                  </td>
                  <td class="text-md text-white font-light pb-4">
                    DOUBLE EYELID SURGERY
                  </td>
                </tr>
                <tr>
                  <td valign="top">
                    <svg class="w-5 h-5 text-white" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M9 5l7 7-7 7"></path></svg>
                  </td>
                  <td class="text-md text-white font-light pb-4">
                    NOSTRIL TRIM
                  </td>
                </tr>
                <tr>
                  <td valign="top">
                    <svg class="w-5 h-5 text-white" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M9 5l7 7-7 7"></path></svg>
                  </td>
                  <td class="text-md text-white font-light pb-4">
                    FACE LIFT
                  </td>
                </tr>
                <tr>
                  <td valign="top">
                    <svg class="w-5 h-5 text-white" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M9 5l7 7-7 7"></path></svg>
                  </td>
                  <td class="text-md text-white font-light pb-4">
                    CHIN AUGMENTATION
                  </td>
                </tr>
              </table>
            </div>
            <div class="w-full lg:w-6/12">
              <table cellpadding="0" cellspacing="0" class="w-full">
                <tr>
                  <td valign="top">
                    <svg class="w-5 h-5 text-white" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M9 5l7 7-7 7"></path></svg>
                  </td>
                  <td class="text-md text-white font-light pb-4">
                    DOUBLE CHIN REMOVAL
                  </td>
                </tr>
                <tr>
                  <td valign="top">
                    <svg class="w-5 h-5 text-white" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M9 5l7 7-7 7"></path></svg>
                  </td>
                  <td class="text-md text-white font-light pb-4">
                    BREAST LIFT/ REDUCTION
                  </td>
                </tr>
                <tr>
                  <td valign="top">
                    <svg class="w-5 h-5 text-white" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M9 5l7 7-7 7"></path></svg>
                  </td>
                  <td class="text-md text-white font-light pb-4">
                    LIP AUGMENTATION
                  </td>
                </tr>
                <tr>
                  <td valign="top">
                    <svg class="w-5 h-5 text-white" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M9 5l7 7-7 7"></path></svg>
                  </td>
                  <td class="text-md text-white font-light pb-4">
                    ARM LIFT 
                  </td>
                </tr>
                <tr>
                  <td valign="top">
                    <svg class="w-5 h-5 text-white" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M9 5l7 7-7 7"></path></svg>
                  </td>
                  <td class="text-md text-white font-light pb-4">
                    AREOLA REPAIR/REDUCTION
                  </td>
                </tr>
              </table>
            </div>
          </div>
          <div class="w-full lg:w-6/12 md:flex">
            <div class="w-full lg:w-6/12">
              <table cellpadding="0" cellspacing="0" class="w-full">
                <tr>
                  <td valign="top">
                    <svg class="w-5 h-5 text-white" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M9 5l7 7-7 7"></path></svg>
                  </td>
                  <td class="text-md text-white font-light pb-4">
                    NOSELIFT
                  </td>
                </tr>
                <tr>
                  <td valign="top">
                    <svg class="w-5 h-5 text-white" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M9 5l7 7-7 7"></path></svg>
                  </td>
                  <td class="text-md text-white font-light pb-4">
                    LIPOSUCTION
                  </td>
                </tr>
                <tr>
                  <td valign="top">
                    <svg class="w-5 h-5 text-white" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M9 5l7 7-7 7"></path></svg>
                  </td>
                  <td class="text-md text-white font-light pb-4">
                    TUMMY TUCK
                  </td>
                </tr>
                <tr>
                  <td valign="top">
                    <svg class="w-5 h-5 text-white" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M9 5l7 7-7 7"></path></svg>
                  </td>
                  <td class="text-md text-white font-light pb-4">
                    HAND REJUVENATION
                  </td>
                </tr>
                <tr>
                  <td valign="top">
                    <svg class="w-5 h-5 text-white" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M9 5l7 7-7 7"></path></svg>
                  </td>
                  <td class="text-md text-white font-light pb-4">
                    JAW CONTOURING
                  </td>
                </tr>
              </table>
            </div>
            <div class="w-full lg:w-6/12">
              <table cellpadding="0" cellspacing="0" class="w-full">
                <tr>
                  <td valign="top">
                    <svg class="w-5 h-5 text-white" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M9 5l7 7-7 7"></path></svg>
                  </td>
                  <td class="text-md text-white font-light pb-4">
                    MOLE REMOVAL
                  </td>
                </tr>
                <tr>
                  <td valign="top">
                    <svg class="w-5 h-5 text-white" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M9 5l7 7-7 7"></path></svg>
                  </td>
                  <td class="text-md text-white font-light pb-4">
                    CLEFT LIP/ CLEFT PALATE
                  </td>
                </tr>
                <tr>
                  <td valign="top">
                    <svg class="w-5 h-5 text-white" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M9 5l7 7-7 7"></path></svg>
                  </td>
                  <td class="text-md text-white font-light pb-4">
                    TRAUMA INJURIES
                  </td>
                </tr>
                <tr>
                  <td valign="top">
                    <svg class="w-5 h-5 text-white" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M9 5l7 7-7 7"></path></svg>
                  </td>
                  <td class="text-md text-white font-light pb-4">
                    BURN INJURIES
                  </td>
                </tr>
                <tr>
                  <td valign="top">
                    <svg class="w-5 h-5 text-white" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M9 5l7 7-7 7"></path></svg>
                  </td>
                  <td class="text-md text-white font-light pb-4">
                    SKIN TUMORS
                  </td>
                </tr>
              </table>
            </div>
          </div>
        </div>
      </div>
      <div class="w-full pt-4 pb-4 lg:pt-8 lg:pb-8"></div>
    </div>
    
    <div class="silver-bg pt-12 pb-12">
      <div class="w-full pt-4 pb-4 lg:pt-8 lg:pb-8"></div>
        <div class="container">
        <div class="w-full text-center text-2xl md:text-xl lg:text-4xl leading-tight text-dark-white font-semi-bold pb-8">
          CONTACT US
        </div>
        <div class="w-full md:flex">
          <div class="w-full md:w-6/12 lg:w-5/12 md:pr-6 pb-10 md:pb-0">
            <div class="w-full text-md text-gold font-bold pb-2">BRANCHES</div>
            <div class="w-full pb-6">
              <table cellpadding="0" cellspacing="0" class="w-full">
                <tr>
                  <td width="5%" valign="top">
                    <svg class="w-5 h-5 text-gold" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M9 5l7 7-7 7"></path></svg>
                  </td>
                  <td class="text-md text-gray-800 font-light pb-3">
                    UERMMMCI, Quezon City
                    <br>
                    <a href="https://www.google.com/maps/place/UERM+Memorial+Hospital/@14.6073087,121.0183594,17z/data=!4m8!1m2!2m1!1sUniversity+of+the+East+Ramon+Magsaysay+Memorial+Medical+Center,+64+Aurora+Boulevard,++Dona+Imelda+Subdivision,+Sta.+Mesa,+Quezon+City,+1113+Metro+Manila!3m4!1s0x3397b627c9f62f67:0xfca4d02e012bf0a1!8m2!3d14.6070065!4d121.0203777" target="_blank" class="text-md text-gold font-medium hover:text-dark-800 transition"><i>View Map</i></a>
                  </td>
                </tr>
                <tr>
                  <td width="5%" valign="top">
                    <svg class="w-5 h-5 text-gold" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M9 5l7 7-7 7"></path></svg>
                  </td>
                  <td class="text-md text-gray-800 font-light pb-3">
                    Centuria Medical Makati
                    <br>
                    <a href="https://www.google.com/maps/place/Centuria+Medical+Makati/@14.5659874,121.0258811,17z/data=!4m8!1m2!2m1!1sThe+Surgery+Center.,+Centuria+Medical+Makati.,+Century+City,+Kalayaan+cor.+1208,+Salamanca,+Makati,+Metro+Manila!3m4!1s0x3397c9074989d6ef:0xac3dc81c540d7cf8!8m2!3d14.5664178!4d121.0284215" target="_blank" class="text-md text-gold font-medium hover:text-dark-800 transition"><i>View Map</i></a>
                  </td>
                </tr>
                <tr>
                  <td width="5%" valign="top">
                    <svg class="w-5 h-5 text-gold" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M9 5l7 7-7 7"></path></svg>
                  </td>
                  <td class="text-md text-gray-800 font-light pb-3">
                    Clinica Antipolo
                    <br>
                    <a href="https://www.google.com/maps/place/Clinica+Antipolo+Hospital+%26+Wellness+Center/@14.5895445,121.1677828,17z/data=!4m8!1m2!2m1!1sClinica+Antipolo+Hospital+and+Wellness+Center,+L.+Su,+Antipolo,+1870+Rizal!3m4!1s0x3397b8acb79fece5:0x4bd88f4b4e489e64!8m2!3d14.589515!4d121.170052" target="_blank" class="text-md text-gold font-medium hover:text-dark-800 transition"><i>View Map</i></a>
                  </td>
                </tr>
                <tr>
                  <td width="5%" valign="top">
                    <svg class="w-5 h-5 text-gold" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M9 5l7 7-7 7"></path></svg>
                  </td>
                  <td class="text-md text-gray-800 font-light">
                    The Medical City South Luzon, Sta. Rosa, Laguna
                    <br>
                    <a href="https://www.google.com/maps/place/The+Medical+City+South+Luzon/@14.2484331,121.0651073,17z/data=!3m1!4b1!4m5!3m4!1s0x33bd7d47c2237d67:0xb3b9b4a439f27dca!8m2!3d14.2484331!4d121.067296" target="_blank" class="text-md text-gold font-medium hover:text-dark-800 transition"><i>View Map</i></a>
                  </td>
                </tr>
              </table>
            </div>
            <div class="w-full text-md text-gold font-bold pb-2">EMAIL ADDRESS</div>
            <div class="w-full pb-6">
              <a href="mailto:firmaloplasticsurgery@gmail.com" target="_blank" class="text-md text-gray-800 font-light hover:text-yellow-600 transition">firmaloplasticsurgery@gmail.com</a>
            </div>
            <div class="w-full text-md text-gold font-bold pb-2">PHONE NUMBER</div>
            <div class="w-full pb-6">
              <a href="tel:09175046056" target="_blank" class="text-md text-gray-800 font-light hover:text-yellow-600 transition">0917 504 6056</a>
            </div>
            <div class="w-full flex items-center">
              <div class="pr-3 pt-2">
                <a href="https://www.facebook.com/drvicefirmalo" target="_blank" class="">
                  <svg xmlns="http://www.w3.org/2000/svg" width="18" height="24" viewBox="0 0 8.563 16">
                    <path id="Path_2" style="fill: #2196f3;" data-name="Path 2" d="M8.719-5H6.375V2H3.25V-5H.719V-7.906H3.25v-2.187a4.448,4.448,0,0,1,.469-2.125,3.142,3.142,0,0,1,1.328-1.328A4.192,4.192,0,0,1,7.031-14q.5,0,1.063.047a8.2,8.2,0,0,1,.875.109l.313.031v2.469H8.031a1.588,1.588,0,0,0-1.266.453,1.64,1.64,0,0,0-.391,1.109v1.875H9.156Z" transform="translate(-0.719 14)"/>
                  </svg>  
                </a>
              </div>
              <div class="pl-3 pt-2">
                <a href="https://www.instagram.com/drvicefirmalo/" target="_blank" class="">
                  <svg xmlns="http://www.w3.org/2000/svg" width="26" height="26" viewBox="0 0 15.75 15.75">
                    <path id="Path_3" style="fill: #e91e63;" data-name="Path 3" d="M5.045-9.615A3.9,3.9,0,0,1,7.91-10.793a3.9,3.9,0,0,1,2.865,1.178A3.9,3.9,0,0,1,11.953-6.75a3.9,3.9,0,0,1-1.178,2.865A3.9,3.9,0,0,1,7.91-2.707,3.9,3.9,0,0,1,5.045-3.885,3.9,3.9,0,0,1,3.867-6.75,3.9,3.9,0,0,1,5.045-9.615Zm1,4.729a2.54,2.54,0,0,0,1.863.773,2.54,2.54,0,0,0,1.863-.773,2.54,2.54,0,0,0,.773-1.863,2.54,2.54,0,0,0-.773-1.863A2.54,2.54,0,0,0,7.91-9.387a2.54,2.54,0,0,0-1.863.773A2.54,2.54,0,0,0,5.273-6.75,2.54,2.54,0,0,0,6.047-4.887Zm6.732-6.732a.884.884,0,0,1,.264.65.943.943,0,0,1-.264.668.856.856,0,0,1-.65.281.913.913,0,0,1-.668-.281.913.913,0,0,1-.281-.668.856.856,0,0,1,.281-.65.943.943,0,0,1,.668-.264A.884.884,0,0,1,12.779-11.619ZM15.75-9.984q.035.949.035,3.234T15.75-3.516A4.848,4.848,0,0,1,14.467-.193a4.724,4.724,0,0,1-3.322,1.248q-.949.07-3.234.07t-3.234-.07a4.825,4.825,0,0,1-3.3-1.266A3.393,3.393,0,0,1,.633-1.2a4.974,4.974,0,0,1-.4-1.09,5.458,5.458,0,0,1-.123-1.23Q.035-4.465.035-6.75t.07-3.27a4.778,4.778,0,0,1,1.266-3.27,4.737,4.737,0,0,1,3.3-1.3q.949-.035,3.234-.035t3.234.035a4.848,4.848,0,0,1,3.322,1.283A4.848,4.848,0,0,1,15.75-9.984ZM14.063-2.109a3.813,3.813,0,0,0,.176-.721q.07-.439.105-1.055t.035-1V-8.613q0-.387-.035-1t-.105-1.055a3.813,3.813,0,0,0-.176-.721A2.537,2.537,0,0,0,12.551-12.9a3.813,3.813,0,0,0-.721-.176q-.439-.07-1.055-.105t-.984-.035H6.047q-.387,0-1,.035t-1.055.105a3.813,3.813,0,0,0-.721.176,2.537,2.537,0,0,0-1.512,1.512,3.813,3.813,0,0,0-.176.721q-.07.439-.105,1.055t-.035.984v3.375q0,.334.018.861t.053.9q.035.369.105.756t.141.633A2.665,2.665,0,0,0,3.27-.6a3.813,3.813,0,0,0,.721.176q.439.07,1.055.105t.984.035H9.773q.387,0,1-.035T11.83-.422A3.813,3.813,0,0,0,12.551-.6,2.665,2.665,0,0,0,14.063-2.109Z" transform="translate(-0.035 14.625)"/>
                  </svg>
                </a>
              </div>
              <div class="pl-3 pt-2">
                <a href="https://www.youtube.com/channel/UCi2o86S97BhLJ8UHYGKsNLg" target="_blank" class="">
                  <svg xmlns="http://www.w3.org/2000/svg" width="40" height="40" viewBox="-35.20005 -41.33325 305.0671 247.9995">
                    <path d="M229.763 25.817c-2.699-10.162-10.65-18.165-20.748-20.881C190.716 0 117.333 0 117.333 0S43.951 0 25.651 4.936C15.553 7.652 7.6 15.655 4.903 25.817 0 44.236 0 82.667 0 82.667s0 38.429 4.903 56.85C7.6 149.68 15.553 157.681 25.65 160.4c18.3 4.934 91.682 4.934 91.682 4.934s73.383 0 91.682-4.934c10.098-2.718 18.049-10.72 20.748-20.882 4.904-18.421 4.904-56.85 4.904-56.85s0-38.431-4.904-56.85" fill="red"/><path d="M93.333 117.559l61.333-34.89-61.333-34.894z" fill="#fff"/>
                  </svg>
                </a>
              </div>
              <div class="pl-3 pt-1">
                <a href="https://www.tiktok.com/@doctorvicefirmalo?lang=en" target="_blank" class="">
                  <svg width="30" height="30" viewBox="0 0 30 30" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M21.4706 10.8293C23.3998 12.2133 25.7632 13.0276 28.3157 13.0276V8.09826C27.8326 8.09837 27.3508 8.04781 26.8782 7.94733V11.8274C24.3259 11.8274 21.9628 11.0131 20.0331 9.62925V19.6886C20.0331 24.7208 15.9682 28.7999 10.9542 28.7999C9.08338 28.7999 7.34451 28.2323 5.90002 27.2588C7.54866 28.9505 9.84782 30 12.3914 30C17.4057 30 21.4708 25.9209 21.4708 20.8885V10.8293H21.4706V10.8293ZM23.244 5.85624C22.258 4.77528 21.6107 3.37834 21.4706 1.83397V1.19995H20.1084C20.4513 3.16285 21.6209 4.83984 23.244 5.85624ZM9.07138 23.3973C8.52054 22.6724 8.22287 21.7857 8.2242 20.8741C8.2242 18.5725 10.0835 16.7064 12.3773 16.7064C12.8048 16.7063 13.2297 16.772 13.6371 16.9017V11.8622C13.161 11.7967 12.6805 11.7689 12.2002 11.7791V15.7016C11.7925 15.5719 11.3674 15.506 10.9398 15.5064C8.64595 15.5064 6.78679 17.3723 6.78679 19.6742C6.78679 21.3017 7.71611 22.7108 9.07138 23.3973Z" fill="#FF004F"/>
                    <path d="M20.0331 9.62915C21.9628 11.013 24.3258 11.8273 26.8781 11.8273V7.94722C25.4535 7.64267 24.1922 6.8955 23.2439 5.85624C21.6207 4.83973 20.4512 3.16275 20.1083 1.19995H16.5301V20.8883C16.522 23.1835 14.6659 25.042 12.377 25.042C11.0282 25.042 9.82993 24.3968 9.07103 23.3973C7.71586 22.7108 6.78654 21.3016 6.78654 19.6743C6.78654 17.3726 8.64569 15.5065 10.9395 15.5065C11.379 15.5065 11.8026 15.5752 12.1999 15.7017V11.7792C7.27401 11.8814 3.31238 15.9206 3.31238 20.8884C3.31238 23.3682 4.29892 25.6163 5.90008 27.2589C7.34456 28.2323 9.08344 28.8 10.9543 28.8C15.9684 28.8 20.0332 24.7207 20.0332 19.6886V9.62915H20.0331Z" fill="black"/>
                    <path d="M26.8782 7.94725V6.89811C25.5934 6.90006 24.3339 6.53899 23.2439 5.85617C24.2088 6.91633 25.4794 7.64733 26.8782 7.94725ZM20.1084 1.19998C20.0757 1.01239 20.0505 0.823564 20.0331 0.634017V0H15.0925V19.6885C15.0846 21.9835 13.2287 23.842 10.9395 23.842C10.2675 23.842 9.63298 23.6819 9.07106 23.3974C9.82996 24.3968 11.0282 25.0419 12.3771 25.0419C14.6658 25.0419 16.5221 23.1836 16.5301 20.8884V1.19998H20.1084ZM12.2002 11.7793V10.6624C11.7873 10.6057 11.3711 10.5773 10.9544 10.5775C5.93989 10.5774 1.875 14.6567 1.875 19.6885C1.875 22.8432 3.47258 25.6233 5.90021 27.2587C4.29905 25.6162 3.31251 23.368 3.31251 20.8883C3.31251 15.9207 7.27404 11.8814 12.2002 11.7793Z" fill="#00F2EA"/>
                  </svg>
                </a>
              </div>
            </div>
          </div>
          <div id="contact-area" class="w-full md:w-6/12 lg:w-7/12">
            <!-- feedback.activamedia.com.sg script begins here -->
            <iframe allowTransparency="true" style="min-height:400px; height:inherit; overflow:auto;" width="100%" id="contactform123" name="contactform123" marginwidth="0" marginheight="0" frameborder="0" src="https://feedback.activamedia.com.sg/my-contact-form-4256295.html"><p>Your browser does not support iframes. The contact form cannot be displayed. Please use another contact method (phone, fax etc)</p></iframe>
            <!-- feedback.activamedia.com.sg script ends here -->
          </div>
        </div>
      </div>
      <div class="w-full pt-4 pb-4 lg:pt-8 lg:pb-8"></div>
    </div>

    <div class="dark-bg pt-12 pb-12">
      <div class="w-full pt-4 pb-4 lg:pt-8 lg:pb-8"></div>
      <div class="container">
        <div class="w-full text-center text-2xl md:text-xl lg:text-4xl leading-tight text-white font-semi-bold pb-8">
            VIDEO LINKS
        </div>
        <div class="w-full md:flex">
          <div class="w-full md:w-4/12 pb-8 md:pb-0 pr-0 md:pr-4">
            <iframe width="100%" height="280" src="https://www.youtube.com/embed/ribSku4hnSg?start=180" frameborder="0" allow="autoplay; encrypted-media" allowfullscreen></iframe>
          </div>
          <div class="w-full md:w-4/12 pb-8 md:pb-0 pl-0 md:pl-2 pr-0 md:pr-2">
            <iframe width="100%" height="280" src="https://www.youtube.com/embed/rddNE7g1lX8" frameborder="0" allow="autoplay; encrypted-media" allowfullscreen></iframe>
          </div>
          <div class="w-full md:w-4/12 pl-0 md:pl-4">
            <iframe width="100%" height="280" src="https://www.youtube.com/embed/AEdFoOy96f0" frameborder="0" allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
          </div>
        </div>
      </div>
      <div class="w-full pt-4 pb-4 lg:pt-8 lg:pb-8"></div>
    </div>

    <div class="silver-bg pt-12 pb-12">
      <div class="w-full pt-4 pb-4 lg:pt-8 lg:pb-8"></div>
      <div class="container">
        <div class="w-full text-center text-2xl md:text-xl lg:text-4xl leading-tight text-dark-white font-semi-bold pb-8">
          Articles
        </div>
        <div class="w-full md:flex md:flex-wrap">
          <div class="w-full md:w-3/12 pb-8 md:pb-0 px-0 md:px-3">
            <div class="w-full text-center pb-6">
              <a href="https://businessmirror.com.ph/2023/06/01/dr-vice-firmalo-keeping-up-with-the-fast-paced-developments-in-beauty-and-anti-aging/" target="_blank"><img src="/images/article04.jpg" alt="" /></a>
            </div>
            <div class="w-full text-sm text-gold font-bold pb-2">
              <a href="https://businessmirror.com.ph/2023/06/01/dr-vice-firmalo-keeping-up-with-the-fast-paced-developments-in-beauty-and-anti-aging/" target="_blank" class="hover:text-yellow-600 transition">Dr. Vice Firmalo: Keeping up with the fast-paced developments in beauty and anti-aging</a>
            </div>
            <div class="text-sm text-gray-800 font-light">
              <i>BusinessMirror</i>
            </div>
          </div>
          <div class="w-full md:w-3/12 pb-8 md:pb-0 px-0 md:px-3 pt-6 md:pt-0">
            <div class="w-full text-center pb-6">
              <a href="https://www.philstar.com/lifestyle/health-and-family/2021/04/05/2089042/male-breast-reduction-surgeon-explains-link-between-man-boobs-and-mental-health" target="_blank"><img src="/images/article01.jpg" alt="" /></a>
            </div>
            <div class="w-full text-sm text-gold font-bold pb-2">
              <a href="https://www.philstar.com/lifestyle/health-and-family/2021/04/05/2089042/male-breast-reduction-surgeon-explains-link-between-man-boobs-and-mental-health" target="_blank" class="hover:text-yellow-600 transition">Male breast reduction: Surgeon explains link between 'man boobs' and mental health</a>
            </div>
            <div class="text-sm text-gray-800 font-light">
              <i>philstar GLOBAL</i>
            </div>
          </div>
          <div class="w-full md:w-3/12 pb-8 md:pb-0 pl-0 md:pl-2 px-0 md:px-3 pt-6 md:pt-0">
            <div class="w-full text-center pb-6">
              <a href="https://www.manilatimes.net/2021/03/27/public-square/gynecomastia-living-with-man-boobs/855983" target="_blank"><img src="/images/article02.jpg" alt="" /></a>
            </div>
            <div class="w-full text-sm text-gold font-bold pb-2">
              <a href="https://www.manilatimes.net/2021/03/27/public-square/gynecomastia-living-with-man-boobs/855983" target="_blank" class="hover:text-yellow-600 transition">Gynecomastia: Living with man-boobs</a>
            </div>
            <div class="text-sm text-gray-800 font-light">
              <i>The Manila Times</i>
            </div>
          </div>
          <div class="w-full md:w-3/12 px-0 md:px-3 pt-6 md:pt-0">
            <div class="w-full text-center pb-6">
              <a href="https://lifestyle.inquirer.net/380389/gynecomastia-living-with-man-boobs/" target="_blank"><img src="/images/article03.jpg" alt="" /></a>
            </div>
            <div class="w-full text-sm text-gold font-bold pb-2">
              <a href="https://lifestyle.inquirer.net/380389/gynecomastia-living-with-man-boobs/" target="_blank" class="hover:text-yellow-600 transition">Gynecomastia: Living with man-boobs</a>
            </div>
            <div class="text-sm text-gray-800 font-light">
              <i>LIFESTYLE.INQ</i>
            </div>
          </div>
        </div>
      </div>
      <div class="w-full pt-4 pb-4 lg:pt-8 lg:pb-8"></div>
    </div>

    <div class="w-full bg-black pt-5 pb-6">
      <div class="container">
        <div class="w-full text-sm font-light text-white text-center">
          Maintained and Developed by AMPH Advertising Agency Inc.
        </div>
      </div>
    </div>

    <!--Begin Chatpatient Live Chat Code-->
    <div id="comm100-button-624"></div>
  </div>
</template>
<script>
import Headernav from '@/components/Headernav.vue'
import carousel from 'vue-owl-carousel'
import vueSmoothScroll from 'vue-smooth-scroll'
export default {
  components: {
    Headernav,
    vueSmoothScroll,
    carousel
  }
}
</script>
<style>
</style>
