<template>
  <div class="w-full border-t-6 border-gold">
    <div class="container flex items-center pt-4 pb-4">
      <div class="w-8/12 md:w-6/12">
        <router-link to="/">
          <img src="/images/logo.jpg" alt="Firmalo Logo" />
        </router-link>
      </div>
      <div class="w-4/12 md:w-6/12 flex justify-end">
        <div class="pl-2">
          <a href="tel:09175046056">
            <svg class="w-16 h-16" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path stroke-linecap="round" stroke-linejoin="round" stroke-width="1.2" d="M12 18h.01M8 21h8a2 2 0 002-2V5a2 2 0 00-2-2H8a2 2 0 00-2 2v14a2 2 0 002 2z"></path></svg>
          </a>
        </div>
        <div class="text-lg text-black text-left hidden md:block">
          <p class="text-md leading-tight text-gray-800 font-light pb-1 md:pb-0">BOOK AN APPOINTMENT NOW!</p>
          <a href="tel:09175046056" target="_blank" class="text-lg lg:text-4xl font-medium leading-tight text-gold hover:text-gray-800 transition">
            09175046056
          </a>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'Headernav'
}
</script>

<style>
</style>
